<template>
  <div>
    <div class="row" v-if="!loading_menu">
      <div class="col-3">
        <h5 class="text-muted">All Sections</h5>
        <ul class="academy-nav">
          <li v-for="(section, i) in sections" :key="`section-${i}`">
            <router-link
              :to="{ name: 'moderator.academy.show', params: { id: section.id } }"
              :class="section.id == current.id ? 'link-active' : ''"
              >#{{ section.sort }} {{ section.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col-9">
        <div v-if="!loading_content">
          <h4>Section #{{ current.sort }} {{ current.title }}</h4>
          <p>
            <span class="mr-4"
              ><strong>Courses:</strong> {{ current.courses }}</span
            >
            <span class="mr-4"
              ><strong>Weeks:</strong> {{ current.weeks }}</span
            >
            <span><strong>Videos:</strong> {{ current.videos }}</span>
          </p>
          <div v-html="current.about"></div>
          <hr />
          <course-list />
        </div>
        <loading v-if="loading_content" />
      </div>
    </div>
    <loading v-if="loading_menu" />
  </div>
</template>

<script>
import CourseList from "./courses/Index";

export default {
  components: {
    CourseList,
  },

  mounted() {
    this.fetchMenu();
  },

  data() {
    return {
      loading_menu: true,
      loading_content: true,
      sections: [],
      current: {},
    };
  },

  methods: {
    fetchMenu() {
      this.loading_menu = true;

      this.$axios.get("/api/v1/moderator/academy/sections").then((response) => {
        this.sections = response.data.sections;
        this.loading_menu = false;
        this.fetchContent()
      });
    },

    fetchContent() {
      this.loading_content = true;

      this.$axios
        .get(`/api/v1/moderator/academy/sections/${this.$route.params.id}`)
        .then((response) => {
          this.current = response.data.section;
          this.loading_content = false;
        });
    },
  },

  computed: {
    section_id() {
      return this.$route.params.id;
    },
  },

  watch: {
    section_id() {
      this.fetchContent();
    },
  },
};
</script>
